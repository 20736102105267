import { gql } from '@apollo/client';
import { memberGQL } from '../gql/memberGQL';

export const GET_MEMBER = gql`
  query GetMember($id:UUID!){
    member(id:$id){
        ${memberGQL}
        documents {
            documentId
            memberId
            name
            description
            expiries
            cpd
            cpdRequested
            cpdRejected
            cpdReceived
        }
        notes {
            noteId
            memberId
            value
            createdDate
        }
    }
  }
`;

export const GET_MEMBER_USERNAME = gql`
  query GetUsername($userId:String!){
    username(userId:$userId)
  }
`;

export const GET_MEMBERS = gql`
  query GetMemberList($skip: Int, $take: Int, $show: String, $keywords: String, $orderBy: String, $orderByDesc: Boolean) {
    memberList(skip: $skip, take: $take, show: $show, keywords: $keywords, orderBy: $orderBy, orderByDesc: $orderByDesc) {
      members {
          ${memberGQL}
      },
      page,
      pageSize,
      totalPages
    }
  }
`;


export const GET_EMDR_TRAINING_LEVELS = gql`
  {
    emdrTrainingLevels {
        emdrTrainingLevelId,
        name
    }
  }
`;

export const GET_PROFESSIONALREGISTRATIONS = gql`
  {
    professionalRegistrations {
      name
      professionalRegistrationId
    }
  }
`;

export const GET_PROFESSIONS = gql`
  {
    professions {
      name
      professionId
    }
  }
`;

export const GET_REGIONS = gql`
  {
    regions {
        regionId,
        name
    }
  }
`;